import flightLoader from "./flight-loader.json";
import globeFlightLoader from "./globe-flight-loader3.json"
import flightSearchLoader from "./flight-search-loader.json";
import noFlightsFound from "./no-flights-found.json";
import successGifBookThroughAdmin from "./book-through-admin-success.json";
import paymentInProgress from "./payment-in-progress.json";
import successGifBookMyself from "./success-gif-book-myself.json";
import failedGifBookMyself from "./booking-failed-gif.json";
import confetti from "./confetti.json";
import ethnicFaresAnimation from "./ethnic-fares-animation.json";
import specialFaresAnimation from "./special-offers-animation.json";
import pageNotFound from "./page-not-found.json";

const normalLogo = "https://storage.googleapis.com/errances_voyages_assets/logos_and_assets/errances-normal-logo.svg";
const transparentLogo = "https://storage.googleapis.com/errances_voyages_assets/logos_and_assets/final-logo.svg";
const transparentLogoWithLoveIsInTheAir = "https://storage.googleapis.com/errances_voyages_assets/logos_and_assets/errances-logo-with-love-is-in-the-air.svg";
const appleColorIcon = "https://storage.googleapis.com/errances_voyages_assets/logos_and_assets/apple.svg"
const googleColorIcon = "https://storage.googleapis.com/errances_voyages_assets/logos_and_assets/google.svg"
const facebookColorIcon = "https://storage.googleapis.com/errances_voyages_assets/logos_and_assets/facebook-color.svg";
const flightImage = "https://storage.googleapis.com/errances_voyages_assets/logos_and_assets/flight-image.png";
const emiratesLogo = "https://storage.googleapis.com/errances_voyages_assets/logos_and_assets/emirates-logo.svg";
const emiratesFlight = "https://storage.googleapis.com/errances_voyages_assets/logos_and_assets/emirates-flight.png";
const logoWithTitleAndLoveIsInTheAir = "https://storage.googleapis.com/errances_voyages_assets/logos_and_assets/New%20logo.svg";
const logoWithoutTitle = "https://storage.googleapis.com/errances_voyages_assets/logos_and_assets/logo-without-title.svg";
const homeBanner = "https://storage.googleapis.com/errances_voyages_assets/logos_and_assets/home-banner.svg";

const flightHead = "https://storage.googleapis.com/errances_voyages_assets/logos_and_assets/flight-head.svg";
const flightTail = "https://storage.googleapis.com/errances_voyages_assets/logos_and_assets/flight-tail.svg";
const aboutUsImage = "https://storage.googleapis.com/errances_voyages_assets/logos_and_assets/about-us-image.jpg";
const valueAndVisionImage = "https://storage.googleapis.com/errances_voyages_assets/logos_and_assets/values-and-vision-img.jpeg"

const googlePlayIcon = "https://storage.googleapis.com/errances_voyages_assets/logos_and_assets/google-play-store-icon.svg";
const appleStoreIcon = "https://storage.googleapis.com/errances_voyages_assets/logos_and_assets/apple-icon.svg";
const appleStoreIconWhite = "https://storage.googleapis.com/errances_voyages_assets/logos_and_assets/apple-store-icon-white.svg";
const springRight = "https://storage.googleapis.com/errances_voyages_assets/logos_and_assets/spring-right.svg";
const springLeft = "https://storage.googleapis.com/errances_voyages_assets/logos_and_assets/spring-left.svg";
const bestFlightImage = "https://storage.googleapis.com/errances_voyages_assets/logos_and_assets/best-flight-image.svg";
const radarImage = "https://storage.googleapis.com/errances_voyages_assets/logos_and_assets/radar-image.svg";

const localEventsImage = "https://storage.googleapis.com/errances_voyages_assets/logos_and_assets/local-events-image.svg";
const customizationImage = "https://storage.googleapis.com/errances_voyages_assets/logos_and_assets/customization-image.svg";
const worldMapImage = "https://storage.googleapis.com/errances_voyages_assets/logos_and_assets/world-map.svg";
const spiralCircleImage = "https://storage.googleapis.com/errances_voyages_assets/logos_and_assets/spiral-circles.svg";
const titleLogoWithoutName = "https://storage.googleapis.com/errances_voyages_assets/logos_and_assets/errances-logo-without-name.svg";
const geoChart = "https://storage.googleapis.com/errances_voyages_assets/logos_and_assets/geo-chart.svg";
const visaTrackerBanner = "https://storage.googleapis.com/errances_voyages_assets/logos_and_assets/visa-tracker-banner_svg.jpeg";
const formalitiesBanner = "https://storage.googleapis.com/errances_voyages_assets/logos_and_assets/formalities-banner.svg";
const securePaymentBanner = "https://storage.googleapis.com/errances_voyages_assets/logos_and_assets/secure-payment-banner.svg";
const packagesBanner = "https://storage.googleapis.com/errances_voyages_assets/logos_and_assets/packages-banner_svg.jpeg";
const indiaTourPackage = "https://storage.googleapis.com/errances_voyages_assets/logos_and_assets/india-tour-package.svg";
const backgroundPatternImg = "https://storage.googleapis.com/errances_voyages_assets/logos_and_assets/background-patterns%20(2).svg";
const securePaymentBackground = "https://storage.googleapis.com/errances_voyages_assets/logos_and_assets/secure-payment-background_svg.jpeg";
const defaultFlightImage = "https://storage.googleapis.com/errances_voyages_assets/logos_and_assets/4955461_31202.jpg";
const beautifulTajMahalImg = "https://storage.googleapis.com/errances_voyages_assets/beautiful-vertical-shot-taj-mahal-building-agra-india-clear-sky%201.png";
const offerLogo = "https://storage.googleapis.com/errances_voyages_assets/logos_and_assets/Offer%2001%20(1).svg";
const blogsBanner = "https://images.pexels.com/photos/1146708/pexels-photo-1146708.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1";

const archivedPromotions = "https://storage.googleapis.com/errances_voyages_assets/archived_promotions/archived-promotions-img.png";
const conditionForSales = "https://storage.googleapis.com/errances_voyages_assets/Nos_Conditions_Generales_de_Vente%20(2).pdf";
const sunglassBeachImage = "https://storage.googleapis.com/errances_voyages_assets/sunglass-beach-image.png";
const confidentialate = "https://storage.googleapis.com/errances_voyages_assets/Confidentialite%20(1).pdf";
const carouselImgOne = "https://storage.googleapis.com/errances_voyages_assets/home_carousel/pexels-jozef-papp-15687656%20(720p).mp4";
const carouselImgTwo = "https://storage.googleapis.com/errances_voyages_assets/home_carousel/pexels-frans-van-heerden-632522.jpg";
const carouselImgThree = "https://storage.googleapis.com/errances_voyages_assets/home_carousel/pexels-kelly-jack-5709844.jpg";
const carouselImgFour = "https://storage.googleapis.com/errances_voyages_assets/home_carousel/pexels-te-lensfix-1371360.jpg";
const carouselImgFive = "https://storage.googleapis.com/errances_voyages_assets/home_carousel/pexels-vlad-vasnetsov-2354073.jpg";
const carouselImgSix = "https://storage.googleapis.com/errances_voyages_assets/home_carousel/pexels-yogendra-singh-2635390.jpg";


export {
    normalLogo, 
    transparentLogo, 
    transparentLogoWithLoveIsInTheAir,
    logoWithTitleAndLoveIsInTheAir,
    appleColorIcon, 
    googleColorIcon, 
    facebookColorIcon, 
    flightImage,
    emiratesLogo,
    emiratesFlight,
    logoWithoutTitle,
    homeBanner,
    flightLoader,
    flightSearchLoader,
    noFlightsFound,
    flightHead,
    flightTail,
    googlePlayIcon,
    appleStoreIcon,
    appleStoreIconWhite,
    springRight,
    springLeft,
    bestFlightImage,
    radarImage,
    localEventsImage,
    customizationImage,
    worldMapImage,
    spiralCircleImage,
    titleLogoWithoutName,
    geoChart,
    visaTrackerBanner,
    formalitiesBanner,
    securePaymentBanner,
    packagesBanner,
    indiaTourPackage,
    globeFlightLoader,
    backgroundPatternImg,
    aboutUsImage,
    valueAndVisionImage,
    securePaymentBackground,
    successGifBookThroughAdmin,
    paymentInProgress,
    successGifBookMyself,
    failedGifBookMyself,
    defaultFlightImage,
    beautifulTajMahalImg,
    archivedPromotions,
    offerLogo,
    confetti,
    ethnicFaresAnimation,
    specialFaresAnimation,
    pageNotFound,
    blogsBanner,
    conditionForSales,
    sunglassBeachImage,
    confidentialate,
    carouselImgOne,
    carouselImgTwo,
    carouselImgThree,
    carouselImgFour,
    carouselImgFive,
    carouselImgSix
};

export const accreditedLogoList = [
    "https://storage.googleapis.com/errances_voyages_assets/airlines_logo/iata-accredited-logo_svg.png", 
    "https://storage.googleapis.com/errances_voyages_assets/airlines_logo/aspt-logo.svg", 
    "https://storage.googleapis.com/errances_voyages_assets/airlines_logo/atout-france-logo.svg",

]

export const airlinesLogoList = [
    "https://storage.googleapis.com/errances_voyages_assets/airlines_logo/qatar-logo.svg", 
    "https://storage.googleapis.com/errances_voyages_assets/airlines_logo/etihad-airways-logo-alt.png",
    "https://storage.googleapis.com/errances_voyages_assets/airlines_logo/air-india-logo-alt%20-%20Copy.png",
    "https://storage.googleapis.com/errances_voyages_assets/airlines_logo/indigo-logo-alt.png",
    "https://storage.googleapis.com/errances_voyages_assets/airlines_logo/vistara-logo-alt.png",
    "https://storage.googleapis.com/errances_voyages_assets/airlines_logo/swiss-logo-alt.png",
    "https://storage.googleapis.com/errances_voyages_assets/airlines_logo/air-france-logo-alt.png",
    "https://storage.googleapis.com/errances_voyages_assets/airlines_logo/turkish-airlines-logo-alt.png",
    "https://storage.googleapis.com/errances_voyages_assets/airlines_logo/wizz-air-logo-alt.png"
]

export const promos = [
    {
        bgColor: "#7AE480",
        promo: "https://storage.googleapis.com/errances_voyages_assets/archived_promotions/image-1.png",
    },
    {
        bgColor: "#E50F67",
        promo: "https://storage.googleapis.com/errances_voyages_assets/archived_promotions/image-2.png",
    },
    {
        bgColor: "#05BBC8",
        promo: "https://storage.googleapis.com/errances_voyages_assets/archived_promotions/image-3.png",
    },
    {
        bgColor: "#0000FE",
        promo: "https://storage.googleapis.com/errances_voyages_assets/archived_promotions/image-4.png",
    },
    {
        bgColor: "#4DA3B2",
        promo: "https://storage.googleapis.com/errances_voyages_assets/archived_promotions/image-5.png",
    },
    {
        bgColor: "#FEFEFE",
        promo: "https://storage.googleapis.com/errances_voyages_assets/archived_promotions/image-6.png",
    },
    {
        bgColor: "#0000FE",
        promo: "https://storage.googleapis.com/errances_voyages_assets/archived_promotions/promotion2.jpg",
    },
    {
        bgColor: "#053901",
        promo: "https://storage.googleapis.com/errances_voyages_assets/archived_promotions/promotion6.jpg",
    },
    {
        bgColor: "#FEFEFE",
        promo: "https://storage.googleapis.com/errances_voyages_assets/archived_promotions/promotion1.jpg",
    },
    {
        bgColor: "#FEFEFE",
        promo: "https://storage.googleapis.com/errances_voyages_assets/archived_promotions/promotion5.jpg",
    },
    {
        bgColor: "#FEFEFE",
        promo: "https://storage.googleapis.com/errances_voyages_assets/archived_promotions/promotion3.jpg",
    },
    {
        bgColor: "#FF2BB9",
        promo: "https://storage.googleapis.com/errances_voyages_assets/archived_promotions/promotion7.jpg",
    },
    {
        bgColor: "#FEFEFE",
        promo: "https://storage.googleapis.com/errances_voyages_assets/archived_promotions/promotion4.jpg",
    },
] 