import React from 'react';
import { Box, ListItem, Typography, Stack, Button, Tooltip } from '@mui/material';
import { FaWhatsapp, FaFacebookF, FaInstagram, FaApple, FaViber, FaYoutube } from "react-icons/fa";
import { conditionForSales, googlePlayIcon } from '../../Assests/assets';
import { useTranslation } from 'react-i18next';
import { SocialButton, TitleLogo } from '../../Lib/MuiThemes/MuiComponents';
import { Link } from 'react-router-dom';

// import { isSafari } from '../../Lib/utils/helperFunctions';

function Footer() {
  
  const { t } = useTranslation();
  return (
    <Box>
    <Box sx={{
        width: 'auto',
        position: 'static',
        bottom: 0,
        backgroundColor: "#F9F7FE",
        height: "auto",
        display: "flex",
        flexDirection: {
            xs: "column",
            md: "row"
        },
        justifyContent: "space-between",
        py: 8,
        px: {xs: 2, md: 10},
        gap: {xs: 5, md: 10}
    }}>
        <Stack 
            spacing={5}
            // justifyContent="center" 
            alignItems="center"
        >
            <Link to='/'>
              <Box sx={{
                height: "auto",
                width: 350,
              }}>
                <TitleLogo/>
              </Box>
            </Link>
            <Stack direction="row" justifyContent="space-between" gap={2}>
                <ListItem disablePadding>
                        <SocialButton 
                            href='https://www.facebook.com/mithra.vijay.94' 
                            size='large'
                            sx={{
                                "&:hover": {
                                    background: "linear-gradient(200deg, #3b5998 50%, #FFFFFF)",
                                }
                            }}
                        >
                            <FaFacebookF />
                        </SocialButton>
                </ListItem>
                <ListItem disablePadding>
                    <SocialButton 
                        href='https://api.whatsapp.com/message/24S7P6EFFL77J1?autoload=1&app_absent=0' 
                        size='large'
                        sx={{
                            "&:hover": {
                                background: "linear-gradient(45deg, #E5FFCC, #1EBEA5, #00E676, #D0E9EA)",
                            }
                        }}
                    >
                        <FaWhatsapp />
                    </SocialButton>
                </ListItem>
                <ListItem disablePadding>
                        <SocialButton 
                            href='https://www.instagram.com/errancesvoyages/' 
                            size='large'
                            sx={{
                                "&:hover": {
                                    background: "linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d)",
                                }
                            }}
                        >
                            <FaInstagram />
                        </SocialButton>
                </ListItem>
                <ListItem disablePadding>
                        <SocialButton 
                            href='https://www.viber.com/en/download/' 
                            size='large'
                            sx={{
                                "&:hover": {
                                    background: "linear-gradient(120deg, #59267c, #8f5db7, #8A83B3, #e2d4e7, #81cd50)",
                                }
                            }}
                        >
                            <FaViber />
                        </SocialButton>
                </ListItem>
                <ListItem disablePadding>
                        <SocialButton 
                            href="https://www.youtube.com/@ErrancesVoyages"
                            size='large'
                            sx={{
                                "&:hover": {
                                    background: "linear-gradient(120deg, #ff0000, #282828);",
                                }
                            }}
                        >
                            <FaYoutube />
                        </SocialButton>
                </ListItem>
            </Stack>
            <Stack direction="row" spacing={2}>
                <Button onClick={() => window.open("https://bit.ly/errancesvoyages")} variant='contained' sx={{
                    backgroundColor: "text.main",
                    gap: 1,
                    px: 0.5,
                    py: 0,
                    minWidth: 150,
                    "&:hover": {
                        backgroundColor: "text.main"
                    }
                }}>
                    
                    <Box sx={{height: 30, width: 30}}>
                        <img src={googlePlayIcon} alt="icon" />
                    </Box>
                    <Box sx={{textAlign: "left"}}>
                        <Typography sx={{fontSize: 10}} variant='subtitle1' color="text.white">Get it on</Typography>
                        <Typography sx={{fontSize: 10}} variant='h6' color="text.white">Google Play</Typography>
                    </Box>
                </Button>
                
                <Button onClick={() => window.open("https://bit.ly/errancesvoyagesapp")} variant='contained' sx={{
                    backgroundColor: "text.main",
                    gap: 1,
                    px: 0.5,
                    py: 0,
                    minWidth: 150,
                    "&:hover": {
                        backgroundColor: "text.main"
                    }
                }}>
                    
                    <Box sx={{fontSize: 35}}>
                        <FaApple/>
                    </Box>
                    <Box sx={{textAlign: "left"}}>
                        <Typography sx={{fontSize: 10}} variant='subtitle1' color="text.white">Download on the</Typography>
                        <Typography sx={{fontSize: 10}} variant='h6' color="text.white">App Store</Typography>
                    </Box>
                </Button>
            </Stack>
        </Stack>
        <Box sx={{display: "flex", flexDirection: "column", gap: 3}}>
            <Typography sx={{fontWeight: "bold"}} variant='h5' color="primary.main">{t("LINKS")}</Typography>

            <Link style={{ textDecoration: "none" }} to='/visa-enquiry' sx={{cursor: "pointer"}} variant='subtitle1' color="primary.main">
                <Typography sx={{fontWeight: "bold", "&:hover": { color: "red" }}} variant='body1' color="primary.main">{t("visaenquiry")}</Typography>
            </Link>
            <Link style={{ textDecoration: "none" }} to='/contact' sx={{cursor: "pointer"}} variant='subtitle1' color="primary.main">
                <Typography sx={{fontWeight: "bold", "&:hover": { color: "red"}}} variant='body1' color="primary.main">{t("contactUs")}</Typography>
            </Link>
            <Link style={{ textDecoration: "none" }} to={conditionForSales} target='_blank' sx={{cursor: "pointer"}} variant='subtitle1' color="primary.main">
                <Typography sx={{fontWeight: "bold", "&:hover": { color: "red"}}} variant='body1' color="primary.main">{t("conditionForSales")}</Typography>
            </Link>
            <Link style={{ textDecoration: "none" }} to='/travel-insurance' sx={{cursor: "pointer"}} variant='subtitle1' color="primary.main">
                <Typography sx={{fontWeight: "bold", "&:hover": { color: "red"}}} variant='body1' color="primary.main">{t("travelInsurance")}</Typography>
            </Link>
            <Link style={{ textDecoration: "none" }} to='/legal-notices' sx={{cursor: "pointer"}} variant='subtitle1' color="primary.main">
                <Typography sx={{fontWeight: "bold", "&:hover": { color: "red"}}} variant='body1' color="primary.main">{t("legalNotice")}</Typography>
            </Link>
            <Link style={{ textDecoration: "none" }} to='/formalities' sx={{cursor: "pointer"}} variant='subtitle1' color="primary.main">
                <Typography sx={{fontWeight: "bold", "&:hover": { color: "red"}}} variant='body1' color="primary.main">{t("termsAndConditions")}</Typography>
            </Link>
        </Box>
        <Stack spacing={1}>
            <Typography sx={{fontWeight: "bold"}} variant='h3' color="primary.main">ERRANCES VOYAGES</Typography>
            <Typography sx={{fontWeight: "bold"}} variant='h6' color="primary.main">{t("ourContactDetails")}: </Typography>
            <Stack>
                <Typography sx={{fontWeight: "bold", color: "red"}} variant='h6' color="primary.main">{t("headOffice").toUpperCase()}</Typography>
                <Typography sx={{fontWeight: "bold"}} variant='h6' color="primary.main">{t("france")}</Typography>
                <Typography variant='h6' color="primary.main">{t("address")}: 55, Rue Louis Blanc, 75010-Paris, France</Typography>
                <Typography variant='h6' color="primary.main">{t("phone")}: +33 (0) 1 42 09 70 00</Typography>
                <Typography variant='h6' color="primary.main">{t("email")}: paris@errancesvoyages.com</Typography>
            </Stack>
            <Stack>
                <Typography sx={{fontWeight: "bold", color: "red"}} variant='h6' color="primary.main">{t("branchOffice").toUpperCase()}</Typography>
                <Typography sx={{fontWeight: "bold"}} variant='h6' color="primary.main">{t("india")}</Typography>
                <Typography variant='h6' color="primary.main">{t("address")}: No.43, M.G.Road, Thiyagaraja st (corner), Pondicherry-605001</Typography>
                <Typography variant='h6' color="primary.main">{t("phone")}: 0413 - 222 86 00</Typography>
                <Typography variant='h6' color="primary.main">{t("email")}: pondy@errancesvoyages.com</Typography>
            </Stack>
        </Stack>
    </Box>
    <Box sx={{
        width: 'auto',
        position: 'static',
        bottom: 0,
        backgroundColor: "primary.main",
        height: "auto",
        display: "flex",
        flexDirection: {
            xs: "column",
            sm: "row"
        },
        justifyContent: "space-between",
        py: 3,
        px: 10,
        gap: 5
    }}>
        <Box>
            <Typography variant='subtitle1' color="text.white">
                {t("copyrights")}
            </Typography>
        </Box>
        {/* <Box>
            <Typography variant='subtitle1' color="text.white">
                {t("poweredBy")} <Typography component="a" variant='subtitle1' sx={{ textDecoration: "underline", color: "white", cursor: "pointer" }} href='https://www.athera.in/' target='_blank'>ATHERA BUSINESS ENABLERS</Typography>
            </Typography>
        </Box> */}
    </Box>
    </Box>
  )
}

export default Footer