import axios from "axios";

// frontend base url

// export const FRONTEND_URL = "http://localhost:3000/";                             // local

// export const FRONTEND_URL = "https://stagingathera.netlify.app/";              // staging

export const FRONTEND_URL = process.env.REACT_APP_frontendURL;                      // production


// server base url

export const BASE_URL = process.env.REACT_APP_backendURL;       // Production url

// export const BASE_URL = "https://us-central1-athera-staging.cloudfunctions.net";     // staging


export const Axios = async ({method, url, data = null, cancelToken, headers = null, auth = false, ...args}) => {

    // const verifyId = await auth.currentUser.getIdToken();
    // const verifyId = localStorage.getItem("verifyId");
    // console.log(verifyId);

    const config = {
        method: method,
        url: `${BASE_URL}/${url}`,
        headers: !!headers ? headers : {
            "Content-Type": "application/json",
            "Accept": "application/json",
        },
        cancelToken: cancelToken,
        data: data,
        ...args
    }

    try{
        const response = await axios(config);
        return response;
    }catch(err){
        return err;
    }    
} 